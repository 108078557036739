var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-breadcrumb', {
    attrs: {
      "display": ['none', 'inherit']
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('c-breadcrumb-item', {
      key: item.label,
      attrs: {
        "is-current-page": item.isCurrent,
        "font-size": "14px"
      }
    }, [_c('c-breadcrumb-link', {
      staticClass: "breadcrumb-link",
      attrs: {
        "href": item.href,
        "is-current-page": item.isCurrent,
        "color": !item.isCurrent ? ['primary.400'] : [],
        "font-size": "14px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }