<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems" />
    <hr>
    <c-box mt="30px">
      <c-text
        color="primary.400"
        text-transform="uppercase"
      >
        Detail Program
      </c-text>
      <c-text
        font-size="24px"
        font-weight="700"
      >
        Progress Tracker
      </c-text>
    </c-box>
    <c-box
      mt="30px"
      rounded="md"
      border-color="primary.400"
      border-width="1px"
      p="20px"
    >
      <c-box w="full">
        <c-box
          mt="30px"
          w="full"
        >
          <c-flex
            v-if="chartMode === 'graphic'"
            mt="20px"
            h="573px"
            border-color="primary.400"
            border-width="1px"
            px="25px"
            py="20px"
            flex-dir="column"
          >
            <c-flex w="full">
              <c-box
                flex="1"
                w="full"
              />
              <c-box w="260px">
                <c-select
                  v-model="chartFilter"
                  w="260px"
                >
                  <option value="weight">
                    Berat Badan
                  </option>
                  <option value="waist">
                    Lingkar Pinggang
                  </option>
                  <option value="bodyFat">
                    Persen Lemak
                  </option>
                  <option value="muscleMass">
                    Massa Otot
                  </option>
                  <option value="bodyWater">
                    Cairan Tubuh
                  </option>
                  <option value="visceralFat">
                    Lemak Visceral
                  </option>
                  <option value="boneMass">
                    Massa Tulang
                  </option>
                  <option value="BMR">
                    Body Mass Ratio
                  </option>
                </c-select>
              </c-box>
            </c-flex>
            <c-box mt="70px">
              <Charts
                key="current-chart"
                :chart-data="chartData"
              />
            </c-box>
          </c-flex>
          <c-flex
            v-else
            mt="20px"
            border-color="primary.400"
            border-width="1px"
            px="25px"
            py="20px"
            flex-dir="column"
          >
            <c-box
              display="flex"
              pb="20px"
              overflow-x="auto"
              max-w="850px"
            >
              <c-box
                v-for="item in progressList"
                :key="item.id"
                d="flex"
                flex-dir="column"
                align-items="center"
              >
                <c-box
                  h="270px"
                  w="200px"
                  bg="gray"
                  mx="5px"
                  d="flex"
                  align-items="center"
                  justify-content="center"
                >
                  <a
                    :href="item.progressPhoto"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      v-chakra
                      :src="item.progressPhoto"
                    >
                  </a>
                </c-box>
                <c-box
                  mt="30px"
                  pos="relative"
                  w="full"
                  d="flex"
                  align-items="center"
                  justify-content="center"
                  border-top-width="2px"
                  border-top-color="#008C8136"
                >
                  <c-box
                    pos="absolute"
                    top="-15px"
                    h="30px"
                    w="30px"
                    border-width="10px"
                    border-color="white"
                    rounded="full"
                    bg="primary.400"
                  />
                  <c-text mt="20px">
                    {{ item.date | formatDate("DD MMM") }}
                  </c-text>
                </c-box>
              </c-box>
            </c-box>
          </c-flex>
          <c-flex
            mt="30px"
            flex-dir="column"
          >
            <c-flex
              mt="30px"
              flex-dir="column"
            >
              <CardProgress
                v-for="item in progressList"
                :id="item.id"
                :key="item.id"
                :date="item.date"
                :weight="item.overview.weight || '-'"
                :waist="item.overview.waist || '-'"
                :body-fat="item.overview.bodyFat || '-'"
                :muscle-mass="item.overview.muscleMass || '-'"
                :body-water="item.overview.bodyWater || '-'"
                :visceral-fat="item.overview.visceralFat || '-'"
                :bone-mass="item.overview.boneMass || '-'"
                :bmr="item.overview.BMR || '-'"
                @click="onDetailClick(item.id)"
              />
            </c-flex>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script >
import Charts from '@/components/chart';
import Breadcrumbs from '@/components/breadcrumb.vue';
import CardProgress from '@/views/nutritionists/progress-tracker/indikator/card-progress';
import { useRouter } from 'vue2-helpers/vue-router';
import { useRoute } from '@/utils/use-route';
import { computed, ref, watch } from '@vue/composition-api';
import { useActions } from '@/stores';
import { getChartData } from '@/utils/get-chart-data';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const $router = useRouter();
  const $route = useRoute();
  const clientId = computed(() => $route.value?.params.clientId);
  const breadcrumbItems = computed(() => {
    // let clientId = this.clientId
    return [{
      label: 'Manajemen Klien',
      href: $router.resolve({
        name: 'admin.clients'
      }).href
    }, {
      label: 'Detail Klien',
      href: $router.resolve({
        name: 'admin.clients-detail',
        params: {
          clientId: clientId.value
        }
      }).href
    }, {
      label: 'Progress & Tracker',
      href: null,
      isCurrent: true
    }];
  });
  const chartFilter = ref('bodyFat');
  const chartMode = ref('graphic');
  const progressList = ref([]);
  const chartData = computed(() => getChartData(progressList.value, chartFilter.value));
  const {
    getProgressData
  } = useActions('admProgressTracker', ['getProgressData']);
  watch(clientId, async clientId => {
    if (clientId == null) return;
    let res = await getProgressData(clientId);
    progressList.value = res;
  });

  function onDetailClick() {}

  return {
    breadcrumbItems,
    chartFilter,
    chartMode,
    progressList,
    chartData,
    onDetailClick
  };
};

__sfc_main.components = Object.assign({
  Breadcrumbs,
  Charts,
  CardProgress
}, __sfc_main.components);
export default __sfc_main;
</script>
